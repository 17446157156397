<template>

<div class="login" :class="{'is-loading': is.loading}">

	<h1><span>rbc</span>guide</h1>

	<input type="email" v-model="model.email" class="login-text" placeholder="Enter email" />
	<input ref="password" type="password" v-model="model.password" class="login-text" placeholder="Enter password" v-on:keypress.enter="onSubmit" />

	<button class="login-button" :disabled="!isValid" :class="{'is-invalid': !isValid}" v-on:click="onSubmit" v-on:submit="onSubmit">
		<template v-if="!is.loading">Login</template>
		<app-icon icon="loading" v-if="is.loading" />
	</button>
	
</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: false
			},
			model: {
				email: '',
				password: ''
			}
		}

	},

	computed: {

		isValid: function() {

			return this.model.email && this.model.password

		}

	},

	methods: {

		onSubmit: function() {

			if (this.isValid) {

				this.$refs.password.blur()

				this.is.loading = true
				
				this.$store.dispatch('login', this.model).then(function() {

					this.$router.push({
						name: 'Dashboard'
					})

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.login {
	width: 320px;
}

.login.is-loading {
	pointer-events: none;
}

.login h1 {
	font-size: 48px;
	font-weight: 300;
	color: #fff;
	text-align: center;
	color: rgba(255, 255, 255, 0.8);
	margin-bottom: 20px;
}

.login h1 span {
	color: #fff;
	font-weight: 400;
}

.login-text {
	border: 2px solid #fff;
	height: 40px;
	border-radius: 12px;
	padding: 0px 10px;
	width: 100%;
	text-align: center;
	color: #fff;
	font-size: 16px;
	margin-bottom: 10px;
	transition: all 100ms linear;
}

.login-text:focus {
	background-color: #fff;
	color: #3256A7;
	transform: scale(1.04);
}

.login-text::placeholder {
	color: rgba(255, 255, 255, 0.8);
}

.login-button {
	background-color: #fff;
	color: #3256A7;
	display: block;
	margin: 0px auto 0px auto;
	height: 40px;
	width: 120px;
	padding: 0px 20px;
	font-size: 16px;
	border-radius: 12px;
	margin-top: 10px;
	transition: all 100ms linear;
}

.login.is-loading .login-button,
.login-button:focus {
	transform: scale(1.04);
}

.login-button.is-invalid {
	opacity: 0.5;
	pointer-events: none;
}

</style>
